<template>
  <div class="bg_view">
    <el-form v-loading="showLoading" v-if="externalUserId && isHaveAuth == 1" label-width="130px" :model="ruleForm" label-position="right" :rules="rules" ref="myForm" autocomplete="off" @validate="validateEvent" :disabled="isSaveInfo">
      <!-- 外部联系人ID -->
      <el-form-item label="外部联系人ID:">
        <div class="id_info_view">
          <div class="id_tx">{{ externalUserId }}</div>
          <el-button size="mini" type="primary" @click="copyExternalUserId" :disabled="false">复制</el-button>
        </div>
      </el-form-item>
      <!-- 负责人 -->
      <el-form-item label="负责人:">
        <div class="manager_tx">{{ managerName }}</div>
      </el-form-item>
      <!-- 飞书链接 -->
      <el-form-item label="飞书文档:">
        <el-link type="primary" :underline="false" @click="openWordAction">{{ wordInfo.name }}</el-link>
      </el-form-item>
      <!-- 渠道名称 -->
      <el-form-item label="渠道名称:" prop="channelName">
        <el-autocomplete placeholder="请输入渠道名称" v-model.trim="ruleForm.channelName" size="small" :fetch-suggestions="channelNameQuerySearch" @select="isSelectAction('channelName')"></el-autocomplete>
      </el-form-item>
      <!-- 项目总姓名 -->
      <el-form-item label="项目总姓名:" prop="pdName">
        <el-autocomplete placeholder="请输入项目总姓名" v-model.trim="ruleForm.pdName" size="small" :fetch-suggestions="pdNameQuerySearch" @select="isSelectAction('pdName')"></el-autocomplete>
      </el-form-item>
      <!-- 项目总手机号 -->
      <el-form-item label="项目总手机号:" prop="pdPhoneNum">
        <el-input placeholder="请输入项目总手机号" v-model.trim="ruleForm.pdPhoneNum" size="small"></el-input>
      </el-form-item>
      <!-- 物业管家姓名 -->
      <el-form-item label="物业管家姓名:" prop="pmName">
        <el-autocomplete placeholder="请输入物业管家姓名" v-model.trim="ruleForm.pmName" size="small" :fetch-suggestions="pmNameQuerySearch" @blur="pmNameEnterAction" @select="isSelectAction('pmName')"></el-autocomplete>
      </el-form-item>
      <!-- 物业管家手机号 -->
      <el-form-item label="物业管家手机号:" prop="pmPhoneNum">
        <el-input placeholder="请输入物业管家手机号" v-model.trim="ruleForm.pmPhoneNum" size="small"></el-input>
      </el-form-item>
      <!-- 城市 -->
      <el-form-item label="城市:" prop="cityName">
        <el-autocomplete placeholder="请输入城市名称" v-model.trim="ruleForm.cityName" size="small" :fetch-suggestions="cityNameQuerySearch" @select="isSelectAction('cityName')"></el-autocomplete>
      </el-form-item>
      <!-- 小区名称 -->
      <el-form-item label="小区名称:" prop="houseAreaName">
        <el-autocomplete placeholder="请输入小区名称" v-model.trim="ruleForm.houseAreaName" size="small" :fetch-suggestions="houseAreaNameQuerySearch" @select="isSelectAction('houseAreaName')"></el-autocomplete>
      </el-form-item>
      <!-- 业主户数 -->
      <el-form-item label="业主户数:" prop="ownerNum">
        <el-input placeholder="请输入业主户数" v-model.trim="ruleForm.ownerNum" size="small"></el-input>
      </el-form-item>
      <!-- 生效日期 -->
      <el-form-item label="生效日期:" prop="startTime">
        <el-date-picker size="small" v-model="ruleForm.startTime" type="date" placeholder="选择生效日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :editable="false"></el-date-picker>
      </el-form-item>
      <!-- 结束日期 -->
      <el-form-item label="结束日期:" prop="endTime">
        <el-date-picker size="small" v-model="ruleForm.endTime" type="date" placeholder="选择结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :editable="false"></el-date-picker>
      </el-form-item>
      <div style="text-align: center;">
        <el-button type="primary" @click="submitAction" v-if="!isSaveInfo">保存</el-button>
      </div>
    </el-form>
    <el-empty v-if="isHaveAuth == -1" :description="`数据加载中...`" :image="require('@/assets/icon_empty.png')"></el-empty>
    <el-empty v-if="isHaveAuth == 0" :description="`您没有操作权限，请联系管理员！`" :image="require('@/assets/icon_empty.png')"></el-empty>
  </div>
</template>

<script>
import copyText from '@/common/utils';
import API from "@/common/axios";
import * as ww from '@wecom/jssdk';
export default {
  data() {
    return {
      showLoading: false,
      appId: "ww8c91222f5023cad4",
      agentId: "1000047",
      externalUserId: "ww8c91222f5023cad4",
      // 负责人名称 无名称为空字符串
      managerName: "",
      ruleForm: {
        // 渠道名称
        channelName: "",
        // 项目总(project director) 名字 不存在为空字符串
        pdName: "",
        //项目总 手机号  不存在为空字符
        pdPhoneNum: "",
        //物业管家 姓名
        pmName: "",
        // 物业管家手机号
        pmPhoneNum: "",
        // 城市名称
        cityName: "",
        //小区名称
        houseAreaName: "",
        // 业主户数
        ownerNum: "",
        // 开始时间
        startTime: "",
        // 结束时间
        endTime: ""
      },
      //是否有权限 1有权限 0无权限
      isHaveAuth: -1,
      // 飞书文档表格信息
      wordInfo: {
        //飞书文档的 名称
        name: "",
        // 飞书文档 点击跳转的链接
        url: ""
      },
      // 是否保存过
      isSaveInfo: false,
      // 所有渠道列表 去重后
      channelList: [],
      // 所有项目总 名字去重 无数据 为空数组
      pdNameList: [],
      //所有管家信息 去重后 无数据 为空数组
      pmList: [],
      // 城市名字列表 去重后 无数据 为空数组
      cityList: [],
      // 小区名字列表 去重后 无数据 为空数组
      houseAreaList: [],
      rules: {
        channelName: { required: true, trigger: "blur", message: "请输入渠道名称", validator: this.validateText },
        pdPhoneNum: { trigger: "blur", validator: this.validatePhone },
        pmName: { required: true, message: "请输入物业管家名字", trigger: "blur", validator: this.validateText },
        pmPhoneNum: { required: true, trigger: "blur", validator: this.validatePhone },
        cityName: { required: true, trigger: "blur", message: "请输入城市名称", validator: this.validateText },
        houseAreaName: { required: true, trigger: "blur", message: "请输入小区名称", validator: this.validateText },
        ownerNum: { required: true, trigger: "blur", validator: this.validateOwnerNum },
        startTime: { required: true, trigger: "blur", message: "请选择生效日期" },
      },
      isMobile: /Mobi|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|SymbianOS|Opera Mini|IEMobile/.test(navigator.userAgent)
    };
  },
  mounted() {
    this.init();
    // 测试代码
    // this.xbjId = 18618175271;
    // this.externalUserId = "wm1NeYBwAAwaoz8k1XMGbRSSlv-sK8EA";
    // this.getData();
  },
  methods: {
    // 初始化获取授权信息
    init() {
      if (!this.$route.query.code) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&agentid=${this.agentId}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=#wechat_redirect`;
        return;
      }
      API({
        url: "/wechat/getUserByCode.do",
        data: {
          url: window.location.href,
          code: this.$route.query.code,
        }
      }).then(res => {
        if (res.success == 1) {
          this.xbjId = res.data.userId;
          this.initQWAuth(res.data);
        } else {
          this.$message.error(res.errMsg || '授权信息获取失败！');
        }
      }).catch(res => {
        this.$message.success("请关闭当前页面，重新进入！")
      });
    },
    // 企微授权
    initQWAuth(data) {
      ww.register({
        corpId: this.appId,
        agentId: this.agentId,
        jsApiList: ['sendChatMessage', 'getCurExternalContact', 'openDefaultBrowser'],
        getAgentConfigSignature: function () {
          return { ...data, nonceStr: data.noncestr };
        },
        onAgentConfigSuccess: _ => {
          this.getCurExternalContact();
        },
        onAgentConfigFail: _ => {
          this.$message.error('企微授权失败');
        }
      });
    },
    getCurExternalContact() {
      ww.getCurExternalContact({
        success: res => {
          this.externalUserId = res.userId;
          this.getData();
        },
        fail: (err) => {
          this.$message.error('获取外部联系人ID失败' + err);
        },
      });
    },
    // 获取数据
    getData() {
      API({
        url: "/property/queryPropertyInfo.do",
        data: {
          xbjId: this.xbjId,
          externalUserId: this.externalUserId
        }
      }).then(res => {
        if (res.success == 1) {
          let { isHaveAuth, savaInfo, managerName, wordInfo, channelList, pdNameList, pmList, cityList, houseAreaList } = res.data;
          this.isHaveAuth = isHaveAuth;
          if (isHaveAuth == 0) {
            return;
          }
          if (savaInfo) {
            this.isSaveInfo = true;
            this.ruleForm = { ...this.ruleForm, ...savaInfo };
          }
          if (wordInfo) {
            this.wordInfo = wordInfo;
          }
          this.managerName = managerName || "";
          this.channelList = channelList || [];
          this.pdNameList = pdNameList || [];
          this.pmList = pmList || [];
          this.cityList = cityList || [];
          this.houseAreaList = houseAreaList || [];
        } else {
          this.$message.error(res.errMsg);
        }
      }).catch(err => {
        this.$message.error(err);
      });
    },
    // 渠道名称 输入提示
    channelNameQuerySearch(queryString, callback) {
      let list = this.channelList.slice().map(item => {
        return { value: item };
      });
      callback(queryString ? list.filter(item => item.value.includes(queryString)) : list);
    },
    // 项目总 输入提示
    pdNameQuerySearch(queryString, callback) {
      let list = this.pdNameList.slice().map(item => {
        return { value: item };
      });
      callback(queryString ? list.filter(item => item.value.includes(queryString)) : list);
    },
    // 物业管家名称 输入提示
    pmNameQuerySearch(queryString, callback) {
      let list = this.pmList.slice().map(item => {
        return { value: item.name };
      });
      callback(queryString ? list.filter(item => item.value.includes(queryString)) : list);
    },
    // 管家名称选择后提示
    pmNameEnterAction() {
      setTimeout(() => {
        if (!this.ruleForm.pmName) {
          return;
        }
        let findOb = this.pmList.find(item => item.name == this.ruleForm.pmName);
        if (findOb && findOb.phone) {
          this.ruleForm.pmPhoneNum = findOb.phone;
          this.$refs.myForm.validateField('pmPhoneNum');
        }
      }, 200);
    },
    // 城市名称 输入提示
    cityNameQuerySearch(queryString, callback) {
      let list = this.cityList.slice().map(item => {
        return { value: item };
      });
      callback(queryString ? list.filter(item => item.value.includes(queryString)) : list);
    },
    // 小区名称 输入提示
    houseAreaNameQuerySearch(queryString, callback) {
      let list = this.houseAreaList.slice().map(item => {
        return { value: item };
      });
      callback(queryString ? list.filter(item => item.value.includes(queryString)) : list);
    },
    // 检验手机号
    validatePhone(rule, value, callback) {
      setTimeout(() => {
        if (!value) {
          return rule.field == "pdPhoneNum" ? callback() : callback(new Error('手机号不可为空'));
        }
        const phoneRegex = /^1[3-9]\d{9}$/;
        if (!phoneRegex.test(value)) {
          return callback(new Error('请输入有效的手机号'));
        }
        callback();
      }, 200);
    },
    // 业主户数检验
    validateOwnerNum(rule, value, callback) {
      setTimeout(() => {
        if (!value) {
          return callback(new Error('请输入业主户数'));
        }
        const phoneRegex = /^\d+$/;
        if (!phoneRegex.test(value)) {
          return callback(new Error('业主户数仅支持数字'));
        }
        callback();
      }, 200);
    },
    // 检验文本 不为空
    validateText(rule, value, callback) {
      setTimeout(() => {
        if (!this.ruleForm[rule.field]) {
          return callback(new Error());
        }
        callback();
      }, 200);
    },
    // 复制文字
    copyExternalUserId() {
      copyText(this.externalUserId);
      this.$message({
        message: "复制成功",
        type: "success",
        duration: 1000
      });
    },
    isSelectAction(typeName) {
      this.$refs.myForm.clearValidate(typeName);
      if (typeName == 'pmName' && this.ruleForm.pmName) {
        let findOb = this.pmList.find(item => item.name == this.ruleForm.pmName);
        if (findOb && findOb.phone && this.ruleForm.pmPhoneNum != findOb.phone) {
          this.ruleForm.pmPhoneNum = findOb.phone;
          this.$refs.myForm.validateField('pmPhoneNum');
        }
      }
    },
    validateEvent() {
      // if (this.validateTimeout) {
      //   clearTimeout(this.validateTimeout);
      // }
      // this.validateTimeout = setTimeout(() => {
      //   this.$refs.myForm.clearValidate();
      // }, 1500);
    },
    // 打开默认浏览器
    openWordAction() {
      if (!this.wordInfo.url) {
        return;
      }
      if (this.isMobile) {
        window.open(this.wordInfo.url, '_self');
        return;
      }
      ww.openDefaultBrowser({
        url: this.wordInfo.url,
        fail: (res) => {
          this.$message.error('打开失败-' + res);
        }
      });
    },
    submitAction() {
      this.$refs.myForm.validate(valid => {
        if (valid) {
          this.submitData();
        } else {
          return false;
        }
      });
    },
    submitData() {
      this.showLoading = true;
      let params = Object.assign(this.ruleForm);
      if (!params.startTime) {
        params.startTime = "";
      }
      if (!params.endTime) {
        params.endTime = "";
      }
      params.externalUserId = this.externalUserId;
      API({
        url: "/property/savePropertyInfo.do",
        data: params
      }).then(res => {
        if (res.success == 1) {
          this.isSaveInfo = true;
          this.$message.success("保存成功");
        } else {
          this.message.error(res.errMsg);
        }
      }).catch(err => {
        this.$message.error(err);
      }).finally(_ => this.showLoading = false);
    }
  }
};
</script>

<style lang="scss" scoped>
.bg_view {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  box-sizing: border-box;
  padding: 40px 20px 30px 0;

  /deep/ .el-empty {
    margin-left: 20px;
  }

  /deep/ .el-autocomplete {
    display: block;
  }

  .id_info_view {
    display: flex;
    align-items: center;

    .id_tx {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
  }

  .manager_tx {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

/deep/ .el-date-editor {
  width: 100% !important;
}
</style>

<style>
.el-message-box {
  max-width: 100%;
}

.el-message {
  max-width: 100%;
  min-width: 100px;
}
</style>